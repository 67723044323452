.city
	.user__info
		display: flex
		align-items: center
		margin-top: 2rem
		.avatar
			width: 12rem
			margin-right: 2rem

	img
		margin: 0 auto
		display: block
	.helicopter
		max-width: 30rem
		margin-bottom: -10%
		animation: fly 2s ease-out alternate-reverse infinite

@keyframes fly
	0%
		transform: translateY(-10%) 

	100%
		transform: translateY(10%)