@font-face
	font-family: 'Roboto'
	font-style: normal
	font-weight: 400
	src: url('../fonts/roboto-regular.eot'), url('../fonts/roboto-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto-regular.woff2') format('woff2'), url('../fonts/roboto-regular.woff') format('woff'), url('../fonts/roboto-regular.ttf') format('truetype'), url('../fonts/roboto-regular.svg#Roboto') format('svg')


@font-face
	font-family: 'Roboto'
	font-style: normal
	font-weight: 700
	src: url('../fonts/roboto-700.eot'), url('../fonts/roboto-700.eot?#iefix') format('embedded-opentype'),url('../fonts/roboto-700.woff2') format('woff2'), url('../fonts/roboto-700.woff') format('woff'), url('../fonts/roboto-700.ttf') format('truetype'),  url('../fonts/roboto-700.svg#Roboto') format('svg')

	@font-face
	font-family: 'Tiempos Headline'
	font-style: normal
	font-weight: 200
	src: url('../fonts/TiemposHeadline-Light.woff2') format('woff2'), url('../fonts/TiemposHeadline-Light.woff') format('woff')

@font-face
	font-family: 'Reso'
	font-style: normal
	font-weight: 100
	src: url('../fonts/reso_light.woff') format('woff')

@font-face
	font-family: 'Reso'
	font-style: normal
	font-weight: 400
	src: url('../fonts/reso_regular.woff') format('woff')

@font-face
	font-family: 'Reso'
	font-style: normal
	font-weight: 700
	src: url('../fonts/reso_semibold.woff') format('woff')

h1, h2, h3, h4, h5
	font-family: 'Arial', sans-serif