.duell
	h3
		font-size: 3rem
		margin-bottom: 2rem

	.duell__container
		width: 100%
		padding: 0 2rem 2rem
		@include tablet
			@include grid-areas('liste winner', minmax(40%,50%) minmax(40%,50%), auto, 2rem)
		.left__column
			grid-area: liste
			.dashboard__lose
				max-width: 100%!important
				margin-bottom: 2rem!important
				width: 100%
		.right__column
			grid-area: winner
			.rangliste__top
				padding-bottom: 0!important
				h3
					margin-bottom: 0
		table
			width: 100%
			margin-top: -2rem
			h4
				margin-bottom: 0rem
				margin-top: 2rem
				font-size: 2.4rem
				color: $yellow
			td
				border-bottom: 1px solid $yellow
				padding: 1rem 0
		.dashboard__enemy
			flex: 1 0 50%
			order: 2
			border-top: 1px solid $grey
			padding-top: 2rem
			@include tablet
				padding-top: 0
				border-top: none
				border-left: 1px solid $grey
			.avatar
				width: 18rem
				margin: 0 auto
			.current__rank
				display: block
				text-align: center
				margin-top: 2rem
				*
					font-family: "Reso"
				strong
					font-size: 2rem
					font-weight: 400
				span
					color: $yellow
					font-size: 200%
					font-weight: bold
			.mein__haus
				img
					margin: 0 auto
					width: 100%
					max-width: 34rem
		.dashboard__haus
			flex: 1 0 50%
			padding-bottom: 2rem
			@include tablet
				padding-bottom: 0
			.avatar
				width: 18rem
				margin: 0 auto
			.mein__haus
				img
					margin: 0 auto
					width: 100%
					max-width: 34rem
					max-height: 50rem
			.current__rank
				display: block
				text-align: center
				margin-top: 2rem
				strong
					font-size: 2rem
					font-weight: 400
				*
					font-family: 'Reso'
				span
					color: $yellow
					font-size: 200%
					font-weight: bold

.modis__rangliste
	width: 100%
	@include tablet
		max-width: 100%
	margin-bottom: 2rem
	height: 80vh
	max-height: 50rem
	.rangliste__top
		padding: 0
		padding-bottom: 2rem

		
		input
			color: $black
			padding: 0.5rem 1rem
			font-size: 1.6rem
			display: block
			width: 100%
			border: 1px solid $black
			&:focus
				outline: none
				border: 1px solid $yellow
	.rangliste__container
		height: calc(80vh - 2px)
		max-height: calc(50rem - 2px)
		padding-top: 0
		li
			visibility: visible
			strong
				color: white
				font-weight: normal
			a
				text-decoration: none
			&.isme
				cursor: initial
				strong
					color: $yellow
					font-weight: bold
			&.hidden
				margin: 0
				visibility: hidden
				height: 0
				overflow: hidden
.hauptpreis
	background: $yellow
	padding: 2rem
	*
		color: black

.dashboard__gewinner
	.modis__rangliste
		background: white
		&:after
			content: none
		*
			color: black