.dashboard
	.dashboard__container
		h3
			font-size: 3rem
			margin-bottom: 1rem
		width: 100%
		// max-width: $full
		padding: 0 2rem 2rem
		@include tablet
			padding: 0 4rem 2rem
		margin: 0 auto
		
		@include tablet
			@include grid-areas('wochenpreis haus' 'rangliste haus' 'lose haus', 40vw auto, auto auto auto, 0 2rem)
		@include desktop
			@include grid-areas('wochenpreis haus' 'rangliste haus' 'lose haus', 55rem auto, auto auto auto, 0 2rem)
		.dashboard__haus
			grid-area: haus
			margin-bottom: 2rem
			.avatar
				width: 20rem
				margin: 0 auto
				h3
					font-size: 4rem
					line-height: 1
			@include tablet
				margin-bottom: 0
			.current__ranking
				text-align: center
				margin-bottom: 2rem
				strong
					font-size: 2rem
				h3
					color: $yellow
					font-size: 10rem
					line-height: 1
			.mein__haus
				img
					margin: 0 auto
					width: 100%
					max-width: 35rem
					max-height: 40rem
					object-fit: contain
p.description
	font-size: 1.4rem
		
.dashboard__lose
	grid-area: lose
	padding: 2rem!important
	border: 1px solid $yellow
	margin-bottom: 2rem
	@include tablet
		// margin-bottom: 0rem
	// 	max-width: 40vw
	// 	width: 55rem
	h3
		display: flex
		align-items: center
		justify-content: space-between
		line-height: 1.2
	.number

		font: inherit
		font-family: 'Roboto', sans-serif
		font-size: 1.4rem
		text-align: center
		position: relative
		display: flex
		align-items: center
		justify-content: center
		width: 2.5rem
		height: 2.5rem
		border-radius: 50%
		line-height: 3rem
		background: $yellow
		color: $black
	.star
		width: 12rem
		height: 12rem
		background: url('../img/icon-star.svg') center no-repeat
		background-size: contain
		display: flex
		align-items: center
		justify-content: center
		font-size: 1.4rem
	.splide__track
		&:before
			content: ""
			position: absolute
			left: 0
			top: 0
			width: 6rem
			height: 100%
			background: $black
			display: block
			z-index: 1
		&:after
			content: ""
			position: absolute
			right: 0
			top: 0
			width: 6rem
			height: 100%
			background: $black
			display: block
			z-index: 1
.dashboard__wochenpreis
	grid-area: wochenpreis
	margin-bottom: 2rem
	@include tablet
		// max-width: 40vw
		// width: 55rem
	background: $yellow
	display: flex
	justify-content: space-between
	flex-wrap: wrap
	@include mobile
		flex-wrap: nowrap
	
	.text__area
		display: flex
		flex-direction: column
		justify-content: center
		padding: 2rem
		*
			color: $black
	.image__area
		background: white
		display: flex
		align-items: center
		justify-content: center
		width: 100%
		img
			width: 100%
			object-fit: cover
			max-height: 30rem
		@include mobile
			width: 15rem
			min-width: 15rem
.rangliste
	grid-area: rangliste
.modis__rangliste
	grid-area: rangliste
	border: 1px solid $grey
	margin-bottom: 2rem
	position: relative
	max-height: 35rem
	&:after
		content: ""
		position: absolute
		bottom: 0
		left: 0
		width: 100%
		height: 50px
		background: linear-gradient(rgba($black, 0), $black)
	.rangliste__container
		padding: 2rem!important
		max-height: 33rem
		overflow: auto
		position: relative
		// transition: all 300ms ease
	hr
		margin: 2rem 0
	img
		position: absolute
		left: 50%
		bottom: 2rem
		transform: translateX(-50%)
		width: 3rem
		cursor: pointer
		z-index: 9
		
	@include tablet
		margin-bottom: 0rem
		max-width: 40vw
		width: 55rem
	ol
		margin-bottom: 5rem
		li
			margin-left: 0
			list-style-type: none
			margin-bottom: 0.5rem
			display: flex
			justify-content: space-between
			align-items: center
			> a
				display: flex
				width: 100%
				justify-content: space-between
				align-items: center
			span:not(.punkte)
				opacity: 0.7
				display: inline-block
				margin-left: 0.5rem
				font-size: 80%
			span.punkte
				color: $yellow
				margin-left: auto
				span
					font: inherit
					color: inherit
					width: 2.2rem
					text-align: center
					&:last-child
						color: white
				
			strong
				color: $yellow
.house__count
	display: flex
	align-items: center
	justify-content: center
	width: 100%
	max-width: 24rem
	background: $yellow
	padding: 1rem 2rem
	margin: 2rem auto
	text-align: center
	p
		color: $black