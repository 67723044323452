.avatar
	// cursor: pointer
	display: block
	position: relative
	transform-origin: center
	transition: all 300ms ease
	.avatar__text
		position: absolute
		top: 60%
		left: 50%
		transform: translateX(-50%)
		width: 60%
		text-align: center
		*
			font-family: "Reso"
		p
			font-size: 1.3rem
	&.c2
		p
			color: $black
	svg
		display: block
		width: 100%
		#Body, #Augen, #Bart, #Mund, #Brille
			g, path
				display: none

	@for $i from 1 through 14
		&.b#{$i}
			svg
				#Body #b#{$i}
					display: block
					*
						display: block
	
	@for $i from 1 through 6
		&.e#{$i}
			svg
				#Augen #e#{$i}
					display: block
					*
						display: block
	@for $i from 1 through 5
		&.bd#{$i}
			svg
				#Bart #bd#{$i}
					display: block
					*
						display: block
	@for $i from 1 through 4
		&.m#{$i}
			svg
				#Mund #m#{$i}
					display: block
					*
						display: block
	
	@for $i from 1 through 5
		&.g#{$i}
			svg
				#Brille #g#{$i}
					display: block
					*
						display: block
	
	@for $i from 1 through 8
		&.h#{$i}
			svg
				.st2
					fill: nth($hair, $i)

	@for $i from 1 through 13
		&.c#{$i}
			svg
				.st0
					fill: nth($outfit, $i)
	@for $i from 1 through 6
		&.s#{$i}
			svg
				.st1
					fill: nth($skin, $i)
.minime__list
	width: 80vw
	margin: 0 auto
	.splide__track
		margin: 0
		@include desktop
			margin: 0 6rem
		.splide__slide
			.avatar
				opacity: 0.7
				transform: scale(0.8)
				.avatar__text
					display: none
			&.is-active
				.avatar
					opacity: 1
					transform: scale(1)
					.avatar__text
						display: block
