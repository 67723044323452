*
	margin: 0
	padding: 0
	box-sizing: border-box
	font-family: 'Roboto', sans-serif

html, body
	height: 100%
	font-size: 10px
	line-height: 1.4
	background: $black

.clearfix::after
	content: "."
	display: block
	clear: both
	font-size: 0
	height: 0
	visibility: hidden
h1
	font-size: 3rem
	margin-bottom: 2rem

p, strong, li, em, a
	font-size: 1.6rem

img
	display: block
	max-width: 100%
.main__section
	background: $black
	padding-top: 15rem
	*
		color: white
.content__container
	width: 100%
	max-width: $desktop
	margin: 0 auto
	padding: 2rem
input, ::placeholder
	color: $black!important

h1, h2, h3
	font-family: 'Tiempos Headline', 'Times New Roman', serif
	font-weight: 200

