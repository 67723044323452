.howto
	h2
		font-size: 3rem
		margin-bottom: 2rem
	ul, ol
		margin-bottom: 2rem
		li
			margin-left: 20px
			margin-bottom: 1rem
			strong
				display: block
	ol
		> li
			margin-bottom: 2rem
			li
				margin-bottom: 0

	hr
		border: none
		width: 100%
		height: 2px
		margin: 4rem 0
		background: $grey