.losfee
	.content__container
		text-align: center
		h3
			font-size: 2rem
			padding: 1rem 2rem
			display: inline-flex
			background: white
			color: orangered
			margin-bottom: 2rem
				
	.main__button
		text-decoration: none
		max-width: 25rem
		margin: 0 auto
	.gewinner__liste
		margin-top: 8rem!important
		width: 100%
		max-width: 40rem
		margin: 0 auto
		background: rgba(#000, 0.2)
		padding: 2rem
		&:empty
			display: none
		h2
			font-size: 3rem
			margin-bottom: 2rem
		table
			text-align: left
			margin: 0 auto
			td
				padding: 1rem
				border-bottom: 1px solid white

	.gewinner__modal
		position: fixed
		left: 0
		top: 0
		width: 100%
		height: 100%
		z-index: 1
		background: rgba(black, 0.8)
		display: flex
		align-items: center
		justify-content: center
		.modal__content
			width: 90%
			max-width: 50rem
			height: auto
			max-height: 90vh
			padding: 10rem 4rem
			background: lighten($black, 10%)
			a
				display: block
				width: 100%
				min-width: 30rem
		#confetti
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 100%
			pointer-events: none
		.close
			font-size: 3rem
			color: white
			text-decoration: none
			cursor: pointer
			transform: translate(2rem, -9rem)
			margin-bottom: 2rem
			margin-left: auto
			text-align: right