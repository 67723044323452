.ranking__container
	width: 100%
	max-width: 120rem
	margin: 0 auto
	padding: 2rem
	@include tablet
		@include grid-areas('left right', 1fr 1fr, auto, 1rem)
	.rangliste__top
		padding: 0
		h3
			font-size: 3rem
	.modis__rangliste
		max-height: 100%
		width: 100%
		max-width: 100%
		.rangliste__container
			max-height: 57rem
	.rangliste__vertrieb
		grid-area: left
	.rangliste__recruiting
		grid-area: right
.watchdog
	max-width: 100%
	width: 100%
	height: auto
	display: block
	// overflow: auto
	padding-top: 2rem
	form
		margin: 0 auto
.watch__logo
	margin: 2rem auto
	width: 15rem
.watchbutton
	display: flex
	width: 100%
	max-width: 20rem
	margin: 2rem auto